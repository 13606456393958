<template>
  <h5>
    <OrdersCount
      :color="getStautsColor()"
      :total="data.incidents"
      :open="data.incidents_open"
      >{{ getQta() }}</OrdersCount
    >
  </h5>
</template>

<script>
import get from "lodash/get";
import { producersProgressMap } from "../../../../../../config/global";
import OrdersCount from "../../../share/OrdersCount";

export default {
  name: "ProductsTableCount",

  components: {
    OrdersCount,
  },

  data() {
    return {
      data: {},
    };
  },

  methods: {
    getStautsColor() {
      const status = get(this.data, "status");
      const item = producersProgressMap.find((item) =>
        item.status.includes(status)
      );
      return item ? item.color : "";
    },

    getQta() {
      return get(this.data, "qta");
    },
  },
};
</script>
